
.notfound {
  font-weight: bold;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
}
.ticket-form {
  .v-text-field--filled > .v-input__control > .v-input__slot {
    min-height: 40px;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    min-height: 0px;
    margin-bottom: 0px;
  }
}
